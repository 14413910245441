/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }
}
.font-weight-strong {
  font-weight: 600;
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 60px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
      color: $theme-primary;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 0.9em;
      font-family: var(--font-family);
      font-weight: 600;
      line-height: 1.4em;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: 400;
      font-size: 15px;
      color: #333;
      text-decoration: none;
      border: 0;
      &:hover {
        text-decoration: none;
        border-bottom-width: 1px;
        border-bottom-style: dotted;
        border-bottom-color: inherit;
        -webkit-tap-highlight-color: transparent;
      }
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: icons;
        font-weight: 400;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
#banner-wrap {
  background: #f3f7fc;
}
#points {
  .small {
    font-size: 14px;
  }
  h3 {
    &:before {
      display: inline-block;
      margin: 0 10px;
      width: 40px;
      height: 40px;
      content: '';
      background: transparent url(../images/steps-icon.png) no-repeat 0 0 / cover;
      vertical-align: middle;
      -webkit-transition: opacity 0.25s ease-out 0.25s;
      transition: opacity 0.25s ease-out 0.25s;
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
      margin-bottom: 7px;
    }
    &.step-2:before {
      background-position: -40px 0;
      transition-delay: 0.2s;
    }
    &.step-3:before {
      background-position: -80px 0;
      transition-delay: 0.3s;
    }
  }
}
#points-wrap {
  background: #f8f8f8;
}
.callout {
  border: 1px solid #93caff;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #points-wrap {
    h3 {
      &:before {
        display: block;
        margin: 0 auto 10px auto;
        width: 60px;
        height: 60px;
      }
      &.step-3:before {
        background-position: -120px 0;
      }
      &.step-2:before {
        background-position: -60px 0;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 75%;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
    }
  }
  #banner {
    background: url(../images/banner-person.png) no-repeat;
    background-position: 10px bottom;
    background-size: 450px;
  }
  #points-wrap {
    h3 {
      &:before {
        width: 120px;
        height: 120px;
      }
      &.step-3 {
        &:before {
          background-position: -240px 0;
        }
      }
      &.step-2 {
        &:before {
          background-position: -120px 0;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner {
    background-position: 50px bottom;
  }
}
